import { StorageService } from "./storage.service";
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from "@angular/common/http";
import { UtilsService } from "./utils.service";
import { Observable } from "rxjs/Observable";
import { Document } from "../interfaces/document";
import { DocumentType } from "../interfaces/document-type";
import { map, mergeMap } from "rxjs/operators";
import { forkJoin } from "rxjs/observable/forkJoin";
import { of } from "rxjs/observable/of";
import { Subject } from "rxjs/Subject";
import { ReplaySubject } from "rxjs";

@Injectable()
export class DocumentsService {
  private documentTypeCache: any = {};

  constructor(
    public http: HttpClient,
    public utils: UtilsService,
    private storage: StorageService
  ) {}

  getDocuments(attachDocumentType = false): Observable<Document[]> {
    return this.http
      .get(this.utils.getDocumentsUrl() + "/documents", {
        headers: this.utils.getHeader(this.storage.getTokenData()),
      })
      .pipe(
        map((items: Document[]) => {
          return items.sort((a, b) => (a.id < b.id ? 1 : -1));
        }),
        mergeMap((items: any) => {
          if (attachDocumentType && items.length > 0) {
            const newItems = items.map((item) => {
              return this.getDocumentType(item.documentTypeCode).map((resp) => {
                item.documentType = resp.body;
                return item;
              });
            });
            return forkJoin(newItems);
          } else {
            return of(items);
          }
        })
      ) as Observable<any[]>;
  }

  getDocument(documentId: number): Observable<Document> {
    return this.http.get(
      this.utils.getDocumentsUrl() + "/documentById/" + documentId,
      {
        headers: this.utils.getHeader(this.storage.getTokenData()),
      }
    ) as Observable<Document>;
  }

  getDocumentContent(documentGuid: string): Observable<HttpResponse<Document>> {
    return this.http.get(
      this.utils.getDocumentsUrl() + "/documentByGuid/" + documentGuid,
      {
        observe: "response",
        headers: this.utils.getHeader(this.storage.getTokenData()),
      }
    ) as Observable<HttpResponse<Document>>;
  }

  getDocumentType(docCode: string): Observable<HttpResponse<DocumentType>> {
    if (!this.documentTypeCache[docCode]) {
      this.documentTypeCache[docCode] = new ReplaySubject<any>();

      this.http
        .get(this.utils.getDocumentsUrl() + "/documentType/" + docCode, {
          observe: "response",
          headers: this.utils.getHeader(this.storage.getTokenData()),
        })
        .map((response: HttpResponse<DocumentType>) => {
          if (response.body) {
            if (
              response.body.mimeType === "application/json" &&
              response.body.documentContent
            ) {
              response.body.documentContent = {
                questionGroups: JSON.parse(
                  "[" + atob(response.body.documentContent) + "]"
                ),
              };
            }
            return response;
          }
        })
        .subscribe((result) => {
          this.documentTypeCache[docCode].next(result);
          this.documentTypeCache[docCode].complete();
        });
    }

    return this.documentTypeCache[docCode];
  }

  createDocument(params: any): Observable<number> {
    let urlPath = "";
    urlPath += "/" + params.docCode;
    urlPath += "/" + params.institutionIvzCode;
    urlPath += "?";
    if (params.externalId) {
      urlPath += "&externalId=" + params.externalId;
    }
    urlPath += "&doctorIvzCode=" + params.doctorIvzCode;
    urlPath += "&doctorName=" + params.doctorName;

    let data;
    if (params.documentContent) {
      data = params.documentContent;
    }

    urlPath += "&kzz=" + params.kzz;
    urlPath += "&email=" + params.email;

    return this.http.post(
      this.utils.getDocumentsUrl() + "/createBodyDocument" + urlPath,
      data,
      {
        headers: this.utils.getHeader(this.storage.getTokenData()),
      }
    ) as Observable<number>;
  }

  updateDocument(documentId: number, content?: any): Observable<any> {
    let data: any = {};

    if (content) {
      data = content;
    }

    return this.http.post(
      this.utils.getDocumentsUrl() + "/updateDocument/" + documentId,
      data,
      {
        observe: "response",
        responseType: "text",
        headers: this.utils.getHeader(this.storage.getTokenData()),
      }
    ) as Observable<any>;
  }

  closeDocument(documentId: number, content?: any): Observable<any> {
    let data: any = {};

    if (content) {
      data = new FormData();
      data.append("documentContent", content);
    }
    return this.http.post(
      this.utils.getDocumentsUrl() + "/closeDocument/" + documentId,
      data,
      {
        responseType: "text",
        headers: this.utils.getHeader(this.storage.getTokenData()),
      }
    ) as Observable<any>;
  }
}
