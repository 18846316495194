import * as tslib_1 from "tslib";
import { of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { UtilsService } from "./utils.service";
import { StorageService } from "./storage.service";
import { catchError } from "rxjs/operators";
var PrescriptionsService = /** @class */ (function () {
    function PrescriptionsService(http, utils, storage) {
        this.http = http;
        this.utils = utils;
        this.storage = storage;
    }
    PrescriptionsService.prototype.getPrescriptionsLog = function () {
        var userData = this.storage.getUserData();
        return this.http
            .get(this.utils.getPrescriptionsUrl() + "/getPrescriptions/" + userData.kzz, { headers: this.utils.getHeader(this.storage.getTokenData()) })
            .pipe(catchError(function () {
            return of({ prescriptions: [] });
        }));
    };
    PrescriptionsService.prototype.createPrescriptionOrder = function (params, doctor, location, userLocalId, comment) {
        //console.log(params);
        var payload = {
            user: tslib_1.__assign({ localId: userLocalId }, this.storage.getUserFullData()),
            medications: params,
            comment: comment,
            doctor: {
                doctor: doctor.id,
                doctorName: doctor.desc,
                institution: location.institutionId,
                institutionName: location.institutionName,
            },
        };
        return this.http.post(this.utils.getPrescriptionsUrl() + "/createPrescription", {
            commandHeader: {
                commandId: "",
                commandName: "createPrescription",
                sessionId: this.storage.getUserGUID(),
                destinations: [location.institutionId],
                user: this.storage.getUserFullData(),
                cmdTime: Date.now(),
            },
            headers: this.utils.getHeader(this.storage.getTokenData()),
            payload: JSON.stringify(payload),
        }, {});
    };
    PrescriptionsService.prototype.cancelPrescription = function (id, comment) {
        return this.http.post(this.utils.getPrescriptionsUrl() + "/cancelPrescription/" + id, {
            prescriptionId: id,
            comment: comment,
        }, { headers: this.utils.getHeader(this.storage.getTokenData()) });
    };
    PrescriptionsService.prototype.sendAttachment = function (prescriptionId, filename, base64, desc) {
        if (desc === void 0) { desc = null; }
        var token = this.storage.getTokenData();
        if (!desc) {
            desc = filename;
        }
        var body = new URLSearchParams();
        body.set("metadataDesc", desc);
        body.set("metadataFilename", filename);
        body.set("attachmentBase64Data", base64);
        return this.http.post(this.utils.getPrescriptionsUrl() +
            ("/prescription/" + prescriptionId + "/attachment"), body.toString(), {
            headers: {
                Authorization: "Bearer " + token.accessToken,
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });
    };
    PrescriptionsService.prototype.postAttachmentGuid = function (attachmentId) {
        var userData = this.storage.getUserData();
        var token = this.storage.getTokenData();
        return this.http.post(this.utils.getPrescriptionsUrl() +
            ("/patient/" + userData.kzz + "/attachment/" + attachmentId + "/guid"), {}, {
            headers: { Authorization: "Bearer " + token.accessToken },
        });
    };
    PrescriptionsService.prototype.getAttachmentContent = function (guid) {
        var userData = this.storage.getUserData();
        var token = this.storage.getTokenData();
        return this.http.get(this.utils.getPrescriptionsUrl() + "/patient/" + userData.kzz + "/attachment/guid/" + guid + "/content", {
            headers: { Authorization: "Bearer " + token.accessToken },
            responseType: "blob",
        });
    };
    return PrescriptionsService;
}());
export { PrescriptionsService };
